<template>
   <div class="copyMessage" id="copyMessage">
      <b>Ссылка скопирована в буфер обмена</b>
   </div>
</template>
<script>
export default {
   name: 'CopyMessage'
}
</script>
<style lang="scss" scoped>

.copyMessage {
   width: 343px;
   line-height: 90px;
   text-align: center;
   border-radius: 9px;
   background-color: var(--mf-green);
   color: #fff;
   position: fixed;
   left: calc(50% - 171px);
   transition: .3s;
   bottom: -90px;
   opacity: 0;
   z-index: 100;

   &.show {
      bottom: 50px;
      opacity: 1;
   }

   @media (max-width: 767px) {
      width: calc(100% - 30px);
      left: 15px;

      &.show {
         bottom: 30px;
      }
   }

}

</style>