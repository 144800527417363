<template>
   <footer class="footer wrapper">
      <div class="footer__copyright">
         6+© {{ year }} ПАО «МегаФон»<br>
         Продолжая использовать наш сайт, вы даете согласие на обработку файлов Cookies и
         других пользовательских данных, в соответствии с <a class="footer__link" href="https://www.megafon.ru/ad/politika">Политикой
         конфиденциальности.</a>
      </div>
   </footer>
</template>

<script>
export default {
   name: "Copyright",
   data: () => ({
      year: new Date().getFullYear()
   })
}
</script>

<style lang="scss">

.footer {
   padding-top: 23px !important;
   padding-bottom: 31px !important;

   &__copyright {
      font-size: 12px;
      line-height: 16px;
      max-width: 548px;
   }

   &__link {
      color: var(--text-blue) !important;
   }


   @media (max-width: 767px) {
      padding-top: 43px !important;
      padding-bottom: 66px !important;
   }

}

</style>