<template>
   <div>
      <Header/>
      <main>
         <div class="big-title">
            <div class="wrapper">
               <h1>Помощь и обслуживание клиентов</h1>
               <Tabs @clickQuestion="activeTab = true"
                     @clickInstruction="activeTab = false"
                     :activeTab="activeTab"
               />
            </div>
         </div>
         <div class="wrapper">
            <div v-show="activeTab" class="FAQ">
               <h2>Вопросы по темам</h2>
               <div class="tab-content">
                  <div class="tab-content__container">

                     <div
                        v-for="(category, indexCategory) in categories"
                        :key="category.title"
                        class="tab-content__collapse">
                        <Category
                           :category="category"
                           :idxCategory="indexCategory"
                           :share="share"
                           @usefulInfo="usefulInfo"
                        />
                     </div>

                  </div>
               </div>
            </div>

            <div v-show="!activeTab" class="tab-content">
               <div class="tab-content__container">

                  <div v-for="(instruction, index) in instructions"
                       :key="index" class="tab-content__collapse">
                     <Instruction
                        @collapse="collapse"
                        :index="index"
                        :instruction="instruction"
                        :share="share"
                        :visible="visible"
                     />
                  </div>

               </div>
            </div>

            <CopyMessage/>
         </div>
      </main>
      <Footer/>
   </div>
</template>


<script>
import Tabs from "../components/Tabs";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CopyMessage from "../components/CopyMessage";
import Instruction from "../components/Instruction";
import Category from "../components/Category";
import categories from '../modules/categories.module'
import instructions from '../modules/instructions.module'

export default {
   name: 'App',

   components: {
      Category,
      Instruction,
      CopyMessage,
      Tabs,
      Header,
      Footer
   },

   data() {
      return {
         visible: [],
         share: [],
         activeTab: true,
         categories,
         instructions,
      }
   },

   methods: {
      collapse( i ) {
         this.visible[i] = !this.visible[i]
         this.visible = Object.assign( [], this.visible )
      },
      usefulInfo( idx_c, idx_q, bool ) { // Информация была полезной?
         this.categories[idx_c].questions[idx_q].reviewSent = bool
      },
      scroll( scrollTo ) {
         this.$smoothScroll( {
            scrollTo: scrollTo,
            duration: 700,
            offset: -20,
         } )
      },
      async anchor() { // якорные ссылки
         if ( this.$route.hash ) {
            const hash = this.$route.hash.split( '' )

            // инструкции
            if ( hash.length < 6 ) {
               hash.splice( 0, 2 )
               const hashId = hash.join( '' )
               this.activeTab = false
               setTimeout( () => {
                  const arrInstr = document.querySelectorAll( '.instruction__head' )
                  arrInstr[hashId].click()
                  this.scroll( arrInstr[hashId] )
               }, 500 )

               // вопросы
            } else {
               const hash = this.$route.hash.split( '-' )
               const idCategory = hash[0].split( '' )
               idCategory.splice( 0, 2 )
               const $category = document.querySelector( `[aria-controls="collapseFAQ${ idCategory.join( '' ) }"]` )
               const $answer = document.querySelector( this.$route.hash )

               await new Promise( resolve => { // раскрытие блока
                  setTimeout( () => {
                     if ( $category ) $category.click()
                     if ( $answer ) $answer.click()
                     return resolve()
                  }, 500 )
               } )
               this.scroll( $answer )

            }
         }
      }
   },

   mounted() {
      this.anchor()
   },
}
</script>


<style lang="scss">

.wrapper {
   max-width: 1100px;
   margin: 0 auto;
   padding: 0 15px;
}

.big-title {
   margin-bottom: 28px;
   background-color: #fff;

   h1 {
      font-size: 52px;
      line-height: 56px;
      font-weight: 900;
      max-width: 760px;
      padding: 42px 0 25px;
   }
}

.FAQ h2 {
   font-size: 24px;
   margin-bottom: 16px;
}

.toggle {
   display: block;
   width: 20px;
   height: 20px;
   background: url('../assets/svg/downarrow_121316.svg') center / 13px no-repeat;
   opacity: .7;
   transform: rotateX(20deg);
   margin-left: auto;
   flex-shrink: 0;

   &_collapsed {
      margin-left: 30px;
      transform: rotate(180deg);
   }
}

.tab-content {
   padding: 30px 40px;
   background-color: #fff;

   ul {
      li {
         margin-left: 15px;
         position: relative;

         &:before {
            content: '•';
            position: absolute;
            transform: translateX(-15px);
         }
      }

   }

   .collapsed {
      & + div .btns-question__share-copy {
         display: none;
      }
   }

   .not-collapsed {
      & + div .btns-question__share-copy {
         margin-right: 27px;
         margin-top: -7px;
      }

      .toggle {
         transform: rotateX(160deg);
      }
   }

   &__head {
      font-size: 19px;
      padding: 28px 16px 28px 0;
      margin: 0;
      display: flex;
      align-items: center;
      height: 79px;
      cursor: pointer;
   }

   &__container {
      border-top: 1px solid #D9D9D9;

      h3 {
         font-size: 24px;
         line-height: 69px;
         text-align: center;
         border-bottom: 1px solid #D9D9D9;
         margin-bottom: 5px;
      }
   }

   &__collapse {
      border-bottom: 1px solid #D9D9D9;
      padding: 0 15px;

      .collapse.show {

         .btns-question {
            position: absolute;
            top: 15px;
            right: 17px;
            transition: .1s;
            opacity: 0;
            visibility: hidden;
            display: flex;
            cursor: auto;

            &__close {
               width: 17px;
               height: 17px;
               background: url('../assets/svg/cross.svg') center / cover no-repeat;
               cursor: pointer;
               opacity: .5;
            }
         }

         &:last-child {
            margin-bottom: 15px;
         }

         span.not-collapsed {
            margin-right: 70px;
         }

         span.not-collapsed + .btns-question {
            opacity: 1;
            visibility: visible;
         }

         span.not-collapsed:after {
            content: '';
            position: absolute;
            width: 100%;
            height: calc(100% - 15px);
            top: 0;
            left: 0;
            z-index: -1;
            background-color: #F9F9F9;
         }
      }

   }

}


@media (max-width: 767px) {

   .big-title {
      padding: 0;
      margin-bottom: 35px;

      h1 {
         padding: 30px 0 25px;
         font-size: 30px;
         line-height: 36px;
      }
   }

   h2 {
      font-size: 19px !important;
   }

   .tab-content {
      background-color: transparent;
      padding: 0;

      &__head {
         font-size: 16px;
         padding: 10px 20px 10px 15px;
         margin: 0;
         min-height: 61px;
      }

      &__container {
         border-top: 0;

         h3 {
            font-size: 19px;
            line-height: 23px;
            border-bottom: 0;
            margin-bottom: 13px;
         }

      }

      &__collapse {
         background-color: #fff;
         padding: 0;

         &:last-child {
            border-bottom: 1px solid transparent;
         }

         .collapse.show .btns-question {
            right: 10px;

            &__share {
               margin-right: 30px;
            }
         }

         > div > .collapse {
            padding: 0 8px;

            .collapse {
               margin-bottom: 0 !important;
            }
         }
      }

   }

}
</style>