<template>
   <div id="app">
      <router-view/>
   </div>
</template>

<script>

export default {
   name: 'App',
   components: {}
}
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import "./assets/fonts/Montserrat/stylesheet.css";
@import "./assets/fonts/SourceSansPro/stylesheet.css";
@import "./assets/scss/null.scss";
@import "./assets/scss/colors.scss";

body {
   font-family: 'Source Sans Pro', sans-serif;
   color: #333333;
   background-color: #EDEDED;
}

h1, h2, h3, h4, h5, h6 {
   font-family: Montserrat, sans-serif;
}

ol {
   padding-left: 1rem;
}
</style>
