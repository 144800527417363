<template>
   <div class="instruction" ref="instruction">
      <div
         class="instruction__head tab-content__head"
         @click.self="collapse"
      >
         <picture
            class="instruction__img"
            @click="collapse"
         >
            <img
               :src="require('../assets/img/' + instruction.img)"
               :alt="instruction.img"
            >
         </picture>

         {{ instruction.title }}

         <ShareCopy
            class="instruction__share-copy"
            v-if="visible[index]"
            :index="'i' + index"
            :share="share"
         />
         <span
            @click="collapse"
            :class="{'toggle_collapsed': visible[index]}"
            class="instruction__toggle toggle"
         />
      </div>

      <b-collapse
         v-model="visible[index]"
         :id="'collapse' + index"
      >
         <div class="instruction__text" v-html="instruction.text"/>
         <div class="instruction__download-link">
            <a
               target="_blank"
               :href="instruction.linkDownload"
               class="instruction__link"
            >Скачать инструкцию</a>
            <span>(PDF)</span>
         </div>
      </b-collapse>

   </div>
</template>


<script>
import ShareCopy from "./ShareCopy"

export default {
   name: 'Instruction',
   components: { ShareCopy },
   props: {
      index: Number,
      instruction: Object,
      share: Array,
      visible: Array,
   },
   methods: {
      collapse() {
         this.$emit( 'collapse', this.index )
      }
   }
}
</script>


<style lang="scss">

.instruction {

   &__share-copy {
      margin-top: 3px;
      margin-left: auto;
   }

   &__head {
      padding: 25px 17px 28px 5px;
   }

   &__link {
      color: var(--text-blue) !important;
      margin-right: 5px;

      &:hover {
         text-decoration: underline;
      }
   }

   &__img {
      display: block;
      width: 62px;
      text-align: center;
      margin-right: 30px;

      img {
         max-width: 62px;
         max-height: 45px;
      }
   }

   &__text {
      padding: 10px 35px 10px 16px;
      line-height: 20px;
   }

   &__download-link {
      padding: 15px 16px;

      a + span {
         color: #333333;
         cursor: auto;
      }

      .share {
         visibility: visible;
      }
   }


   @media (max-width: 767px) {
      border: 0;

      &__img {
         width: 36px;
         margin-right: 13px;

         img {
            max-width: 36px;
            max-height: 30px;
         }
      }

   }

}


</style>