<template>
   <div class="asset">

      <div v-if="qst.reviewSent === null" class="asset__container">
         <span class="asset__question">Информация была полезной?</span>

         <span class="asset__yes"
               @click="clickHandler(true)">Да</span>
         <span class="asset__no"
               @click="clickHandler(false)">Нет</span>
      </div>


      <div v-else-if="qst.reviewSent === true"
           class="asset__container">
         <span class="asset__question">Отзыв отправлен. Спасибо!</span>
      </div>


      <div v-else class="asset__container">
         <span class="asset__question">Еще есть вопросы?</span>
         <span class="asset__yes">Отправить вопрос</span>
      </div>

   </div>
</template>
<script>
export default {
   name: 'Asset',
   props: {
      idxCategory: Number,
      idxQst: Number,
      qst: Object,
   },
   methods: {
      clickHandler( bool ) {
         this.$emit( 'usefulInfo', this.idxCategory, this.idxQst, bool )
         this.analytics( bool, this.qst.question )
      },
      analytics( bool, eventLabel ) {
         const event = bool ? 'click_yes' : 'click_no'
         window.gtag( 'event', event, { 'event_category': 'EventHelpMf', 'event_label': eventLabel } )
      },
   },
}
</script>
<style lang="scss" scoped>
.asset {

   span:not(&__question) {
      color: var(--text-blue);
      cursor: pointer;
   }

   &__question {
      color: #999999;
      cursor: default;
   }

   &__yes {
      margin-left: 24px;
   }

   &__no {
      margin-left: 11px;
   }

   @media (max-width: 767px) {
      &__container {
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         padding-bottom: 15px;
         margin-right: -19px;
      }

      span {
         line-height: 18px;
         margin-bottom: 7px;
      }

      &__yes:hover,
      &__no:hover {
         text-decoration: underline;
      }

      &__question {
         width: 100%;
         text-align: center;
         margin-right: 0;
      }

      &__yes {
         margin-left: 0;
      }

      &__no {
         margin-left: 56px;
      }

   }

}
</style>