module.exports = [
   {
      id: 'android_tv',
      img: 'android_tv.png',
      title: 'Android TV',
      text: 'Мощное «железо» для любых задач. Играйте в игры, смотрите видео, слушайте музыку и общайтесь с друзьями без каких-либо ограничений.<br><br>' +
         'Наслаждайтесь непревзойденным и реалистичным качеством картинки.<br><br>' +
         '<ul>' +
         '<li>Dualband Wi-Fi 2,4+5 ГГц</li>' +
         '<li>Android TV (Google Play, YouTube, Google Chromecast, Google Ассистент)</li>' +
         '<li>Версия Android 9 или выше</li>' +
         '<li>Поддержка 4K UHD видео</li>' +
         '<li>Подписка на видео МегаФон ТВ в подарок</li>' +
         '</ul>',
      linkDownload: 'https://home.megafon.ru/uploads/manuals/ATV_KM8.pdf'
   },
   {
      id: 'fr100',
      img: 'fr100.png',
      title: 'Роутер FR100-1',
      text: 'Мощное устройство для стабильного интернета. Сообщите нашему специалисту, что хотите взять роутер в аренду, и мы вам его привезём.<br><br>' +
         '<ul>' +
         '<li>Скорость до 100 Мбит/сек</li>' +
         '<li>Диапазон частот 2,4 и 5 ГГц</li>' +
         '<li>Подключение 3G/4G модема</li>' +
         '<li>Автоматическое обновление ПО</li>' +
         '</ul>',
      linkDownload: 'https://home.megafon.ru/uploads/manuals/MegaFon%20FR100.pdf'
   },
   {
      id: 'fr1000-1',
      img: 'fr1000-1.png',
      title: 'Роутер FR1000-1',
      text: 'Мощное устройство для стабильного интернета. Сообщите нашему специалисту, что хотите взять роутер в аренду, и мы вам его привезём.<br><br>' +
         '<ul>' +
         '<li>4 внешние антенны MU-MIMO</li>' +
         '<li>Скорость до 1000 Мбит/с</li>' +
         '<li>Диапазон частот 2,4 и 5 ГГц</li>' +
         '<li>Подключение 3G/4G модема</li>' +
         '<li>Автоматическое обновление ПО</li>' +
         '</ul>',
      linkDownload: 'https://home.megafon.ru/uploads/manuals/MegaFon%20FR1000-1.pdf'
   },
   {
      id: 'fr1000-2',
      img: 'fr1000-2.png',
      title: 'Роутер FR1000-2',
      text: 'Мощное устройство для стабильного интернета. Сообщите нашему специалисту, что хотите взять роутер в аренду, и мы вам его привезём.<br><br>' +
         '<ul>' +
         '<li>4 внешние антенны MU-MIMO</li>' +
         '<li>Скорость до 1000 Мбит/с</li>' +
         '<li>Диапазон частот 2,4 и 5 ГГц</li>' +
         '<li>Подключение 3G/4G модема</li>' +
         '<li>Автоматическое обновление ПО</li>' +
         '</ul>',
      linkDownload: 'https://home.megafon.ru/uploads/manuals/MegaFon%20FR1000-2.pdf'
   },
   {
      id: 'fr1000l-2',
      img: 'fr1000l.png',
      title: 'Роутер FR1000L-2',
      text: 'Мощное устройство для стабильного интернета. Сообщите нашему специалисту, что хотите взять роутер в аренду, и мы вам его привезём.<br><br>' +
         '<ul>' +
         '<li>4 внешние антенны MU-MIMO</li>' +
         '<li>Скорость до 867 Мбит/с</li>' +
         '<li>Диапазон частот 2,4 и 5 ГГц</li>' +
         '<li>Подключение 3G/4G модема</li>' +
         '<li>Автоматическое обновление ПО</li>' +
         '</ul>',
      linkDownload: 'https://home.megafon.ru/uploads/manuals/MrgaFon%20FR1000L-2.pdf'
   },
   {
      id: 'mf_tv_q5',
      img: 'mf_tv_q5.png',
      title: 'ТВ Приставка МегаФон ТВ Q5',
      text: 'Управляйте эфиром, перематывайте, ставьте на паузу – смотрите как удобно и когда удобно.<br><br>' +
         '<ul>' +
         '<li>Подключается через Wi-Fi</li>' +
         '<li>В комплекте Bluetooth-пульт</li>' +
         '<li>Функция «Родительский контроль»</li>' +
         '</ul>',
      linkDownload: 'https://home.megafon.ru/uploads/manuals/MegaFon%20Q5.pdf'
   },
   {
      id: 'mf_tv_m8s_pro',
      img: 'mf_tv_m8s_pro+.png',
      title: 'ТВ Приставка МегаФон ТВ M8S PRO+',
      text: 'Управляйте эфиром, перематывайте, ставьте на паузу – смотрите как удобно и когда удобно.<br><br>' +
         '<ul>' +
         '<li>Подключается через Wi-Fi</li>' +
         '<li>В комплекте Bluetooth-пульт</li>' +
         '<li>Функция «Родительский контроль»</li>' +
         '</ul>',
      linkDownload: 'https://home.megafon.ru/uploads/manuals/MegaFon%20M8S%20PRO.pdf'
   },
]