<template>
   <div class="share-copy">

      <button type="button" class="share-copy__share"
              tabindex="0"
              @focus="show(index, true)"
              @blur="show(index, false)"
      />

      <button type="button" v-if="share[index]"
              @mousedown="copyLink(index)"
              class="share-copy__copy"
      >Копировать ссылку
      </button>

   </div>
</template>


<script>

export default {
   name: 'ShareCopy',
   props: {
      index: String,
   },
   data() {
      return {
         share: []
      }
   },
   methods: {
      show( i, bool ) { // показать/скрыть кнопку копировать
         this.share[i] = bool
         this.share = Object.assign( [], this.share )
      },
      copyLink( id ) { // копировать ссылку
         navigator.clipboard.writeText( `${ window.origin }/#${ id }` )
         window.gtag( 'event', 'click', { 'event_category': 'EventFMC', 'event_label': 'copylink' } )
         this.showMessCopy()
      },
      showMessCopy() {
         const copyMessage = document.getElementById( 'copyMessage' )
         copyMessage.classList.add( 'show' )
         setTimeout( () => copyMessage.classList.remove( 'show' ), 2500 )
      }
   }
}
</script>


<style lang="scss" scoped>

.share-copy {
   position: relative;

   &__share {
      width: 30px;
      height: 30px;
      background: url('../assets/svg/share.svg') center / 12px no-repeat;
      cursor: pointer;
      position: relative;
   }

   &__copy {
      font-size: 14px;
      height: 53px;
      width: 198px;
      border-radius: 9px;
      padding-left: 42px;
      position: absolute;
      align-items: center;
      z-index: 1;
      right: -12px;
      top: -15px;
      box-shadow: 0 0 15px #00000026;
      background: #fff url('../assets/svg/copy.svg') 15px center / 18.4px no-repeat;
      display: flex;
      cursor: pointer;
   }
}

.not-collapsed {
   .share-copy__share {
      visibility: visible;
   }
}

</style>