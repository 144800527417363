<template>
   <div class="category">
      <div
         class="tab-content__head"
         v-b-toggle="'collapseFAQ' + (category.id || idxCategory)"
         @click="analytics(category.title)"
      >{{ category.title }}
         <span class="toggle"></span>
      </div>

      <b-collapse :id="'collapseFAQ' + (category.id || idxCategory)">
         <div
            v-for="(qst, idxQst) in category.questions"
            :key="(qst.id || idxQst)"
            class="category__question"
         >
            <div>
               <span
                  v-b-toggle="(category.id || idxCategory) + 'q' + (qst.id || idxQst)"
                  :id="'c' + (category.id || idxCategory) + '-q' + (qst.id || idxQst)"
                  @click="analytics(qst.question)"
               >{{ qst.question }}</span>

               <div class="btns-question">
                  <ShareCopy
                     class="btns-question__share-copy"
                     :index="'c' + (category.id || idxCategory) + '-q' + (qst.id || idxQst)"
                     :share="share"
                  />
                  <div
                     v-b-toggle="(category.id || idxCategory) + 'q' + (qst.id || idxQst)"
                     class="btns-question__close"
                  />
               </div>

               <b-collapse
                  :id="(category.id || idxCategory) + 'q' + (qst.id || idxQst)"
                  class="category__answer"
               >
                  <div class="category__answer-container" v-html="qst.answer"/>

                  <Asset
                     :idxCategory="idxCategory"
                     :idxQst="idxQst"
                     :qst="qst"
                     @usefulInfo="usefulInfo"
                  />
               </b-collapse>

            </div>
         </div>
      </b-collapse>
   </div>
</template>


<script>
import Asset from "./Asset"
import ShareCopy from "./ShareCopy"

export default {
   name: 'Category',
   components: { Asset, ShareCopy },
   props: {
      category: Object,
      idxCategory: Number,
      share: Array,
   },
   methods: {
      // "информация была полезной?"
      usefulInfo( ...args ) {
         this.$emit( 'usefulInfo', ...args )
      },
      analytics( eventLabel ) {
         window.gtag( 'event', 'click', { 'event_category': 'EventHelpMf', 'event_label': eventLabel } )
      },
   },
}
</script>


<style lang="scss">
.category {

   img {
      display: block;
      margin: 0 auto;
      padding: 25px 0;
      max-width: 100%;
   }

   &__img_wifi {
      display: flex;
   }

   &__question {
      position: relative;
      z-index: 0;

      > div {
         padding: 15px 35px 15px 16px;

         > span {
            display: inline-block;
            cursor: pointer;
            color: var(--text-blue);
            line-height: 18px;
            margin-right: 20px;
            border-bottom: 1px dashed transparent;
            margin-left: 10px;

            &:before {
               content: '-';
               position: absolute;
               transform: translateX(-10px);
            }

            @media (min-width: 768px) {
               &:hover {
                  border-color: var(--text-blue);
               }
            }

         }
      }
   }

   &__answer-container {
      display: inline-block;
      margin: 15px 0 40px;

      a {
         color: var(--text-blue);

         &:hover {
            text-decoration: underline;
         }
      }

      *:last-child {
         margin-bottom: 0;
      }
   }

   &__answer {
      line-height: 20px;
      padding: 0 14px;
   }


   @media (max-width: 767px) {

      &__answer {
         padding: 0 11px;
         margin-bottom: 0 !important;
      }
   }
}
</style>