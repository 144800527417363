module.exports = [
   {
      title: 'Оплата, баланс, счет',
      questions: [
         {
            question: 'Как узнать свой лицевой счет?',
            answer: 'Номер телефона — это ваш лицевой счёт. С его помощью можно оплатить услуги, войти в личный кабинет и в МегаФон ТВ. ',
            reviewSent: null
         },
         {
            question: 'Как проверить баланс?',
            answer: 'Проверить баланс можно в голосовом меню (позвонив на 0500), с помощью USSD команды *100# или в личном кабинете.',
            reviewSent: null
         },
         {
            question: 'Как пополнить баланс?',
            answer: '<p>Пополнить баланс можно несколькими способами:</p>' +
               '<ol>' +
               '<li>В Личном кабинете: банковской картой, электронным кошельком, по номеру телефона.</li>' +
               '<li>В приложении Сбербанк-онлайн, раздел «Платеж или перевод». Найдите в списке платежей «Интернет и ТВ», укажите поставщика услуг МегаФон, введите номер лицевого счёта и сумму оплаты.</li>' +
               '<li>Наличными через терминалы оплаты: Qiwi, CyberPlat или Элексент, банкоматы Сбербанка России и Московского кредитного банка или пункты приема платежей: МегаФон Ритейл, Связной</li>' +
               '</ol>',
            reviewSent: null
         },
         {
            question: 'Что такое автоплатеж и как его подключить?',
            answer: 'Автоплатеж - это сервис, позволяющий настроить автоматическое пополнение счета с вашей банковской карты. Его можно подключить в личном кабинете. \n' +
               'Данные карты защищены, а переводы зашифрованы, поэтому это совершенно безопасно. Вы сами выбираете, когда и на какую сумму пополнять счёт, и в любой момент можете изменить настройки.\n' +
               'Дата списания абонентской платы соответствует дате подключения тарифа.',
            reviewSent: null
         },
         {
            question: 'Как войти в Личный кабинет, если при подключении продукта #ДляДома вам выдали новую сим-карту МегаФон?',
            answer: '<ol>' +
               '<li>Подключите сим-карту в телефон.</li>' +
               '<li>Откройте страницу входа Личного кабинета, перейдите на вкладку Вход по SMS, введите свой номер телефона и нажмите кнопку «Далее». <br>В течение 5 минут на ваш телефон поступит SMS сообщение с кодом для входа в Личный кабинет МегаФон, с которым вы сможете войти и установить постоянный пароль. <br><br> Если нет возможности подключить сим-карту, позвоните в службу поддержки — 8 800 550‑05‑00 и *0500, сообщите о необходимости сброса пароля от Личного кабинета. Специалист направит временный пароль на ваш e-mail адрес. <br><br> Для того, чтобы сменить пароль зайдите в Личный кабинет, выберите Настройки входа. Введите текущий пароль в поле «Старый пароль», введите новый пароль и нажмите «Сменить пароль».</li>' +
               '</ol>',
            reviewSent: null
         },
      ]
   },
   {
      id: 'mftv',
      title: 'Смотрёшка',
      questions: [ 
         {
            question: 'Смотрёшка вместо Мегафон ТВ',
            answer: '<p>МегаФон ТВ теперь в  Смотрёшке. Ваши учетные записи и подписки сохранены — регистрировать новый аккаунт не требуется. Чтобы продолжить смотреть и управлять подписками скачайте приложение «Смотрёшка» на свое устройство или войдите на <a target="_blank" href="https://smotreshka.tv/">smotreshka.tv</a> по своему номеру МегаФон.</p>',
            reviewSent: null
         }, 
         {
            question: 'Часто задаваемые вопросы',
            answer: '<p><a target="_blank" href="https://smotreshka.tv/mf_help">Здесь</a> можно найти ответы на самые частые вопросы по работе приложения.</p>',
            reviewSent: null
         }, 
         {
            question: 'Какой ТВ-приставкой я пользуюсь?',
            answer: 'Информация о модели вашей ТВ-приставки указана на её коробке или на наклейке со штрихкодом.',
            reviewSent: null
         },
         {
            question: 'Какой MAC-адрес у ТВ-приставки?',
            answer: 'MAC-адрес указан на наклейке со штрихкодом в нижней части приставки.',
            reviewSent: null
         },
         {
            question: 'Как настроить ТВ-приставку?',
            answer: '<p>Воспользуйтесь инструкцией:</p>' +
               '<ul>' +
               '<li>МегаФон ТВ <a target="_blank" href="https://home.megafon.ru/uploads/manuals/MegaFon Q5.pdf">Q5</a>;</li>' +
               '<li><a target="_blank" href="https://home.megafon.ru/uploads/manuals/MegaFon M8S PRO.pdf">М8 PRO+</a>;</li>' +
               '<li><a target="_blank" href="https://home.megafon.ru/uploads/manuals/ATV_KM8.pdf">Android TV</a>.</li>' +
               '</ul>',
            reviewSent: null
         },
         {
            id: 'mftv_reset',
            question: 'Сброс настроек ТВ-приставок',
            answer: `<p>Для сброса настроек ТВ-приставки изучите рекомендации:</p>
            <a target="_blank" href="/#cmftv-qmftv_q5">ТВ-приставка МегаФон ТВ Q5</a> <br>
            <a target="_blank" href="/#cmftv-qmftv_androidtv">ТВ-приставка Android TV</a> <br>
            <a target="_blank" href="/#cmftv-qmftv_m8spro">ТВ-приставка МегаФон ТВ M8S PRO+</a>`,
            reviewSent: null
         },
         {
            id: 'mftv_q5',
            question: 'Сброс настроек ТВ-приставки МегаФон ТВ Q5?',
            answer: '<ol>' +
               '<li>Выключите приставку.</li>' +
               '<li>На её нижней панели найдите отверстие с надписью «R».</li>' +
               '<li>Вставьте в отверстие острый предмет, например, стержень и зажмите.</li>' +
               '<li>Не отпуская предмет, включите приставку и дождитесь надписи: «Идёт обновление».</li>' +
               '</ol>',
            reviewSent: null
         },
         {
            id: 'mftv_androidtv',
            question: 'Сброс настроек ТВ-приставки Android TV?',
            answer: '<ol>' +
               '<li>На рабочем столе приставки откройте настройки.</li>' +
               '<li>Перейдите в раздел «Настройки устройства».</li>' +
               '<li>Выберите раздел «Сброс».</li>' +
               '<li>Нажмите «Сброс», далее — «Удалить всё». </li>' +
               '<li>Готово! Информация о сбросе данных появится на экране.</li>' +
               '</ol>',
            reviewSent: null
         },
         {
            id: 'mftv_m8spro',
            question: 'Сброс настроек ТВ-приставки МегаФон ТВ M8S PRO+',
            answer: '<ol>' +
               '<li>Включите приставку.</li>' +
               '<li>На её задней панели найдите отверстие с надписью «AV».</li>' +
               '<li>Вставьте в отверстие острый предмет, например, стержень, и зажмите до щелчка.</li>' +
               '<li>Продолжая нажимать на кнопку, выключите и включите приставку.</li>' +
               '<li>Дождитесь загрузки на экране телевизора. Отпустите кнопку и следуйте подсказкам на экране.</li>' +
               '</ol>',
            reviewSent: null
         },
         {
            question: 'Что делать, если каналы не загружаются или качество изображение низкое?',
            answer: '<p>Убедитесь в стабильной работе интернета. <a target="_blank" href="https://play.megafon.tv/info">Проверьте</a> скорость подключения в тот момент, когда изображение зависает. Измерить скорость интернета можно с любого устройства, подключенного к роутеру. Для воспроизведения HD-каналов будет достаточно скорости в 10-12 <nobr>Мбит/с.</nobr></p>' +
               '<p>Попробуйте перезагрузить приставку. Для этого отключите её от сети питания, подождите несколько секунд и включите. Также может помочь <a target="_blank" href="/#cmftv-qmftv_reset">сброс настроек</a>.</p>',
            reviewSent: null
         },
         {
            question: 'Что делать, если приставка не реагирует на пульт?',
            answer: '<p>Проверьте батарейки в пульте — они могут разрядиться.</p>' +
               '<p>Активируйте пульт одновременным зажатием клавиш «+» и «-» на 15 секунд. Если это не поможет, полностью <a target="_blank" href="/#cmftv-qmftv_reset">сбросьте настройки</a> приставки.</p>',
            reviewSent: null
         },
      ]
   },
   {
      title: 'Добровольная блокировка',
      questions: [
         {
            question: 'Что такое добровольная блокировка и как ее подключить?',
            answer: 'Это услуга, которая позволяет отключить услуги связи и не оплачивать абонентскую плату на время ее действия.',
            reviewSent: null
         },
         {
            question: 'На какой срок можно приостановить договор?',
            answer: 'Максимальный срок действия - 180 дней, после чего блокировка отключается. По окончанию действия услуги при наличии денежных средств на счете и платных услуг, произойдут списания за них.',
            reviewSent: null
         },
         {
            question: 'Добровольная блокировка — это платная услуга?',
            answer: '1,00 руб./сут. Если на балансе образуется сумма, недостаточная для списания за услугу добровольная блокировка, или 0, блокировка отключается и происходит списание абонентской платы за тариф.',
            reviewSent: null
         },
         {
            question: 'Как подключить добровольную блокировку?',
            answer: 'В личном кабинете;через USSD-команду: *105*1*5*2*1# или *105*2*9*1*1*1#; Позвонить на номер <a href="tel:88005500001">8 800 55-00-001</a>',
            reviewSent: null
         }
      ]
   },
   {
      id: 'web',
      title: 'Вопросы по домашнему Интернету',
      questions: [
         {
            question: 'Полезная информация',
            answer: 'Скорость Интернет-соединения измеряется в Мбит/с. У некоторых провайдеров можно увидеть другое обозначение — МБ/с. Чтобы перевести МБ/с в Мбит/с, умножьте исходную скорость интернета на 8. Например, 37,5 МБ/с = 300 Мбит/с.',
            reviewSent: null
         },
         {
            question: 'Что делать, если не работает Интернет?',
            answer: '<ol>' +
               '<li>Визуально проверьте Интернет-кабель на наличие повреждений.</li>' +
               '<li>Вы подключены:' +
               '<ul>' +
               '<li><a target="_blank" href="/#cweb-qweb_badconnect_router">через роутер;</a></li>' +
               '<li><a target="_blank" href="/#cweb-qweb_badconnect_norouter">напрямую к ПК, минуя роутер</a></li>' +
               '</ul>' +
               '</ol>',
            reviewSent: null
         },
         {
            id: 'web_badconnect_router',
            question: 'Неустойчивое Интернет-соединение через роутер',
            answer: '<ol>' +
               '<li>Перезагрузите роутер — отключите кабель из розетки, подождите 10-15 секунд и включите роутер вновь. </li>' +
               '<li>Проверьте качество Интернет-соединения на других устройствах, подключенных к роутеру, например, на смартфоне. Если зависания наблюдаются на одном устройстве, то перезагрузите его. </li>' +
               '<li>Если проблема наблюдается на всех устройствах, подключите кабель напрямую к ПК, минуя роутер и протестируйте Интернет-соединение в течение нескольких часов. </li>' +
               '<li>Если после выполнения вышеописанных рекомендаций трудности с выходом в Интернет на всех устройствах сохраняются, обратитесь в <a target="_blank" href="https://moscow.megafon.ru/help/contacts/">службу технической поддержки</a>. </li>' +
               '</ol>' +
               '<p>*Причиной проблемы может быть выход из строя роутера. В данном случае рекомендуем произвести <a target="_blank" href="/#ceq-qeq_choice">замену оборудования</a>.</p>',
            reviewSent: null
         },
         {
            id: 'web_badconnect_norouter',
            question: 'Неустойчивое Интернет-соединение напрямую к ПК, минуя роутер',
            answer: '<ol>' +
               '<li>Перезагрузите ПК. </li>' +
               '<li>Проверьте ПК на наличие вирусов. </li>' +
               '<li>Отключите антивирус и проверьте качество работы Интернета. </li>' +
               '<li>Если после выполнения вышеописанных рекомендаций вы по-прежнему испытываете трудности с выходом в Интернет напрямую, то для оперативного решения вашей проблемы, выполните <a target="_blank" href="#cweb-qweb_diagnostic">диагностику</a> на ПК и предоставьте скриншоты с результатами в <a target="_blank" href="https://lk.megafon.ru/inapp/chat">чат технической поддержки</a>. </li>' +
               '</ol>',
            reviewSent: null
         },
         {
            question: 'Нет доступа к конкретному сайту',
            answer: '<ol>' +
               '<li>Если у вас не открывается один или несколько сайтов, проверьте не заблокированы ли они по решению <a target="_blank" href="https://eais.rkn.gov.ru/">Роскомнадзора</a>. </li>' +
               '<li>Если используете VPN-сервис, отключите его и перезагрузите устройство, используемое для выхода в Интернет. </li>' +
               '<li>Проверьте ваше устройство на наличие вредоносного ПО. Попробуйте отключить антивирус, т.к. он может блокировать доступ к ресурсу. </li>' +
               '<li>Попробуйте открыть страницу с помощью другого браузера/на другом устройстве/через мобильную сеть.</li>' +
               '<li>Если с другого браузера/устройства через домашний Интернет доступ к сайту есть, то рекомендуем почистить кэш и cookie-файлы в используемом браузере, перезагрузить устройство. </li>' +
               '<li>Сайт недоступен на всех устройствах через сеть Домашнего Интернета, но открывается посредством мобильного Интернета - <a target="_blank" href="#cweb-qweb_diagnostic">произведите диагностику</a> (обязательно при подключении напрямую) и предоставить скриншоты результатов в <a target="_blank" href="https://lk.megafon.ru/inapp/chat">чат технической поддержки</a>. </li>' +
               '<li>Сайт не открывается и через мобильный интернет – обратитесь в техническую поддержку данного сайта.</li>' +
               '</ol>',
            reviewSent: null
         },
         {
            question: 'Интернет совсем не работает',
            answer: `Если нет доступа ко всем сайтам, перед обращением в техническую поддержку, пожалуйста, проделайте действия, описанные ниже.
               <ol>
               <li>Узнайте баланс в <a target="_blank" href="https://lk.megafon.ru/">Личном кабинете.</a></li>
               <li>Убедитесь, что кабель и коннектор целы. Переподключите кабель. </li>
               <li>Вы подключены:
                <ul>
               <li><a target="_blank" href="/#cweb-qweb_noweb_router">через роутер;</a></li>
               <li><a target="_blank" href="/#cweb-qweb_noweb_norouter">напрямую к ПК, минуя роутер</a></li>
               </ul>
               </li>
               </ol>`,
            reviewSent: null
         },
         {
            id: 'web_noweb_router',
            question: 'Подключение через роутер',
            answer: `
             <ol>
               <li>Проверьте, что интернет-кабель подключён к правильному порту роутера — WAN или Ethernet/Internet. <br>
               <a target="_blank" href="/#i">Подробная информация</a> о портах роутера от МегаФона в разделе «Инструкции». <br>
               <a target="_blank" href="https://rudevice.ru/">Подробная информация</a> о портах роутера от другого производителя. <br>
               <img src=${ require( '../assets/img/faq/router.webp' ) } alt="router"></li>
               <li>Проверьте, проблема наблюдается на одном устройстве или на нескольких? 
                  На одном – вероятнее всего причина в самом устройстве или его настройках. 
                  На всех устройствах - перезагрузите роутер (отключите кабель из розетки, подождите 10-15 секунд и включите роутер).
               </li>
               <li>Проверьте подключение к сети Wi-Fi, кликнув на соответствующий значок в правом нижнем углу экрана вашего ПК. 
               <div class="category__img_wifi">
               <img src=${ require( '@/assets/img/faq/wifi1.png' ) } alt="wifi1">
               <img src=${ require( '@/assets/img/faq/wifi2.png' ) } alt="wifi2">
               </div>
               </li>
               <li>Подключите кабель напрямую к ПК, минуя роутер, и проверьте работу интернета (может потребоваться ввод логина/номера телефона и пароля от личного кабинета). </li>
               </ol>`,
            reviewSent: null
         },
         {
            id: 'web_noweb_norouter',
            question: 'Подключение напрямую к ПК, минуя роутер',
            answer: `
             <p>*Обратите внимание, что в зависимости от поколения Windows названия разделов/иконок для нижеприведённых рекомендаций могут отличаться. Более подробные рекомендации можно найти <a target="_blank" href="#cweb-qweb_PPPoE">здесь</a>.</p>
             <ol>
               <li>Проверьте статус подключения к Интернету, кликнув на соответствующий значок в правом нижнем углу экрана вашего ПК.
               <img src=${ require( '@/assets/img/faq/connect.png' ) } alt="connect">
               Выберите раздел «Параметры сети и Интернет».
               <img src=${ require( '@/assets/img/faq/web_param.png' ) } alt="web_param">
               Выберите «Настройка параметров адаптера». 
               <img src=${ require( '@/assets/img/faq/adapter_param.png' ) } alt="adapter_param">
               В открывшемся окне вы увидите иконку, приведенную на скриншоте ниже.
               <img src=${ require( '@/assets/img/faq/ethernet_param.png' ) } alt="ethernet_param">
               Иконка такого же цвета как на скриншоте, Интернет-соединение установлено и активно.
               Красный крест на иконке – сетевые подключения отсутствуют (Интернет-кабель поврежден/не вставлен в сетевую карту или сетевая карта повреждена/вышла из строя).
               Серая иконка - Интернет-соединение не активно. Включите его, кликнув правой кнопкой мышки и выбрав «включить». <br>
               Данной иконки нет – вероятно проблемы с сетевой картой. Рекомендуем обратиться в сервисный центр для диагностики.
               </li> <br>
               <li>Подключение активно, но страницы не загружаются - проверьте настройки получения IP-адреса и DNS-сервера. <br>
               Откройте папку сетевых подключений, кликните правой кнопкой мышки на иконку Ethernet/Подключение по локальной сети и выберите «Свойства»
               <img src=${ require( '@/assets/img/faq/ethernet_sv.png' ) } alt="ethernet_sv">
               Выделите строку «IP версии 4 (TCP/IPv4)» и нажмите «Свойства».
               <img src=${ require( '@/assets/img/faq/ethernet_ipv4.png' ) } alt="ethernet_ipv4">
               Выделите автоматическое получение IP-адреса и DNS-серверов и нажмите «OK».
               <img src=${ require( '@/assets/img/faq/ipv4_param.png' ) } alt="ipv4_param">
               </li>
               <li>После выполнения вышеописанных рекомендаций по-прежнему испытываете трудности с выходом в Интернет на всех устройствах/при подключении напрямую - обратитесь в службу <a target="_blank" href="https://moscow.megafon.ru/help/contacts/">технической поддержки</a>.</li>
               </ol>`,
            reviewSent: null
         },
         {
            id: 'web_err678',
            question: 'Ошибка 678 (815, 651)',
            answer: `
            Перезагрузите компьютер и переподключите Интернет-кабель.
            <ol>
            <li>Проверьте состояние сетевого адаптера.</li>
            <li><a target="_blank" href="/#cweb-qweb_PPPoE">Пересоздайте PPPoE-соединение.</a></li>
            <li>Если ошибка появляется снова обратитесь в службу <a target="_blank" href="https://moscow.megafon.ru/help/contacts/">технической поддержки</a>.</li>
            </ol>`,
            reviewSent: null
         },
         {
            id: 'web_err619',
            question: 'Ошибка 619 / ошибка 691 (629)',
            answer: `
            <ol>
            <li>Перезагрузите ПК.</li>
            <li>Проверьте баланс в <a target="_blank" href="https://lk.megafon.ru/">Личном кабинете</a>.</li>
            <li><a target="_blank" href="/#cweb-qweb_PPPoE">Пересоздайте PPPoE-соединение.</a></li>
            <li>Возможно, под вашими учетными данными (логином и паролем) уже вошли в сеть. В ситуации, если вы передаете свой роутер третьим лицам, помните, если на нем не сброшены настройки, подключение будет осуществляться под вашими данными. Новому пользователю необходимо сбросить настройки и перенастроить устройство.</li>
            </ol>`,
            reviewSent: null
         },
         {
            id: 'web_wifi',
            question: 'Что делать, если устройство не видит домашнюю сеть Wi-Fi?',
            answer: `
            <ol>
            <li>Перезагрузите роутер и ваше устройство.</li>
            <li>Проверьте, видит ли устройство сеть Wi-Fi.</li>
            <li>Если имя сети не появилось, возможно, роутер сменил её название. Имя сети по умолчанию SSID и пароль Pass можно узнать на наклейке с обратной стороны роутера.</li>
            <li>Проверьте, видит ли другое устройство сеть Wi-Fi. Если нужная сеть доступна на другом устройстве, значит, проблема не в роутере.</li>
            <li>Подключитесь к сети и введите правильный пароль.</li>
            <li>Если пароль введён верно, но устройство не подключается к сети, откройте в настройках компьютера «Центр управления сетями и общим доступом» → «Управление беспроводными сетями» → Выберите вашу точку доступа → «Удалить выбранную сеть» → Закройте настройки → Подключитесь к сети и введите пароль ещё раз.</li>
            <li>Убедитесь, что у вас не включена функция WPS (QSS), позволяющая подключать устройства к роутеру без пароля от беспроводной сети. Обычно кнопка WPS находится на задней панели роутера, а иногда может быть совмещена с кнопкой Reset.</li>
            <li>Если устройство не видит ни одной сети, вы можете:
            <ul>
            <li>проверить, не выключен ли Wi-Fi-модуль сочетанием клавиш «Fn+F3», «Fn+F5» или механической кнопкой, отвечающей за включение модуля;</li>
            <li>перейти в «Центр управления сетями и общим доступом», выбрать «Изменение параметров адаптера» и проверить, не отключено ли беспроводное сетевое соединение.</li>
            </ul>
            </li>
            <li>Обновите драйверы.</li>
            <li>Проверьте, к какой частоте подключается ваше устройство — 2.4 ГГц или 5 ГГц. Частоту 5 ГГц улавливают не все устройства.</li>
            <li>Отключите антивирус на время подключения — он может блокировать доступ к некоторым сетям.</li>
            </ol>`,
            reviewSent: null
         },
         {
            question: 'Где лучше всего разместить роутер?',
            answer: `
            <p>Стены дома или квартиры препятствуют распространению сигнала. Чем ближе роутер к устройству, тем лучше качество соединения.</p>
            <p>Устанавливайте роутер в центре квартиры — это увеличит площадь покрытия сети.</p>
            <p>При возможности пользуйтесь частотой 5 ГГц — она менее загружена, и скорость Интернета выше. </p>`,
            reviewSent: null
         },
         {
            question: 'Как проверить скорость соединения?',
            answer: `
            <p>Воспользуйтесь сервисом <a target="_blank" href="https://www.speedtest.net/">SpeedTest</a>. Выберите свой город и один из серверов МегаФона.</p>
            <p>Чтобы получить наиболее точный результат, закройте все программы, использующие интернет, и измерьте скорость соединения несколько раз.</p>
            <p>Проверьте скорость интернета по кабелю и Wi-Fi. Если скорости отличаются, возможно, есть проблемы в работе роутера. Сделайте скриншоты с измерениями и отправьте их в <a target="_blank" href="http://lk.megafon.ru/inapp/chat">чат с поддержкой</a>.</p>`,
            reviewSent: null
         },
         {
            question: 'Почему скорость интернета может быть низкой?',
            answer: `Скорость может снизиться по причине:
            <ul>
            <li>устаревшего или неподходящего оборудования;</li>
            <li>неправильного расположения роутера;</li>
            <li>работе программ, снижающих скорость соединения — например, торрент-ресурсов;</li>
            <li>наличия на устройстве вредоносного ПО;</li>
            <li>работе firewall или антивируса;</li>
            <li>ограничений на сервере или его загруженности</li>
            </ul>`,
            reviewSent: null
         },
         {
            question: 'Интернет работает на низкой скорости постоянно',
            answer: `
            <ol>
            <li>
            <p>Проверьте, соответствует ли пропускная способность роутера выбранному тарифному плану. Если пользуетесь роутером от МегаФона, перейдите <a target="_blank" href="/#i">по ссылке</a> и откройте раздел «Инструкции». </p>
            <p>Скорость интернета на тарифе можно проверить в Личном кабинете или на нашем официальном сайте, выбрав свой город. Если пропускная способность роутера окажется ниже заявленной скорости интернета на тарифе, смените устройство. Для этого обратитесь в <a target="_blank" href="http://lk.megafon.ru/inapp/chat">чат с поддержкой</a>.</p>
            <p>Узнать пропускную способность роутера другого производителя можно на его сайте.</p>
            </li>
            <li>Проверьте количество пользователей, подключённых к Wi-Fi. Если к вашей точке доступа подключено много устройств, которые вам не принадлежат, смените пароль.</li>
            <li>Используйте частоту соединения 5 ГГц вместо 2,4 ГГц. Важно, чтобы ваши устройства поддерживали эту частоту. </li>
            <li>Сбросьте настройки роутера. Если используете роутер от МегаФона, откройте <a target="_blank" href="https://help.home.megafon.ru/">Помощь и обслуживание клиентов</a> — раздел «Инструкции» — выберите устройство из списка. Если пользуетесь роутером другого производителя, перейдите по <a target="_blank" href="https://rudevice.ru/">ссылке</a>.
            <p>Проверьте мощность в настройках смартфона.</p>
            </li>
            <li>Разместите роутер правильно.</li>
            <li>Обновите прошивку роутера. </li>
            <li>Если компьютер подключён к роутеру по кабелю, то, возможно, проблема в ограниченной скорости на сетевой карте. Проверьте её настройки:
            <ul>
            <li>нажмите комбинацию Win+R;</li>
            <li>в открывшемся окне введите ncpa.cpl и нажмите «ОК»;</li>
            <li>в окне «Сетевые подключения» находится ярлык Еthernet или «Подключение по локальной сети» — кликнете по нему правой кнопкой мышки и выберите «Свойства»;</li>
            <li>проверьте скорость соединения в открывшемся окне. </li>
            </ul>
            </li>
            <li>Если проблемы с соединением возникают на компьютере или ноутбуке, воспользуйтесь антивирусом. Наличие вредоносных ПО также влияет на скорость интернета.</li>
            </ol>`,
            reviewSent: null
         },
         {
            question: 'Низкая скорость Интернета – периодически возникающая неисправность',
            answer: `
            <ol>
            <li>Отрегулируйте качество обслуживания QoS роутера, если устройство имеет эту функцию. Например, если необходимо настроить домашний роутер, то установите приоритет рабочего компьютера над остальными устройствами. </li>
            <li>Освободите загруженность домашней сети: подключите частоту 5 ГГц или используйте подключение по кабелю. Также проверьте количество пользователей, подключённых к Wi-Fi. Если к вашей точке доступа подключено много устройств, которые вам не принадлежат, смените пароль.</li>
            <li>
            <p>Временно отключите антивирус или firewall и <a target="_blank" href="https://www.speedtest.net/">проверьте</a> скорость соединения. </p>
            <p>Если снижение скорости интернета наблюдается только при открытии конкретного сайта, обратитесь в его техническую поддержку</p>
            </li>
           
            </ol>`,
            reviewSent: null
         },
         {
            question: 'Если Вы не можете зайти в Интернет с мобильного телефона/смартфона',
            answer: `
            <ol>
            <li>Если используете VPN-сервис, отключите его и перезагрузите устройство, используемое для выхода в Интернет. Некоторые VPN-сервисы, даже будучи заблокированными, могут снижать скорость Интернета, мешать работе браузеров и приложений.</li>
            <li>Если ваш смартфон <a target="_blank" href="/#cweb-qweb_wifi">не видит сеть Wi-Fi</a>, перезагрузите роутер.</li>
            <li>Если ваш смартфон не видит ни одной сети Wi-Fi, вероятнее всего проблема в настройках Вашего устройства.  </li>
            <li>Наличие интернета на вашем устройстве не гарантирует работу установленных приложений. Если приложение не работает, удалите его и скачайте заново. Неисправность сохраняется? Обратитесь в техподдержку приложения.</li>
            </ol>`,
            reviewSent: null
         },
         {
            question: 'Что делать, если неисправность не устранена?',
            answer: `
            <p>При возникновении вопросов обратитесь к нам <a target="_blank" href="https://moscow.megafon.ru/help/contacts/">любым удобным способом</a>. </p>
            <ol>
            <li>Позвоните на 0500 с номера телефона, на который подключён домашний интернет и ТВ. Если звоните с другого номера, сообщите адрес подключения и ФИО владельца договора. </li>
            <li>Вспомните кодовое слово, если оно есть в договоре, и сообщите его сотруднику при необходимости.</li>
            <li>Подробно опишите суть проблемы: назовите код ошибки, надпись на экране или отправьте скриншот в <a target="_blank" href="http://lk.megafon.ru/inapp/chat">чат с поддержкой</a>.</li>
            <li>Сообщите, если вы уже обращались по данному вопросу.</li>
            <li>Опишите действия, которые вы совершали для решения проблемы, и результат. </li>
            </ol>
            <p>*В случае необходимости будьте готовы предоставить результаты измерений, описанных в разделе «Диагностика перед обращением в тех. поддержку» ниже в <a target="_blank" href="http://lk.megafon.ru/inapp/chat">чат с поддержкой</a>.</p>
            <p>Как связаться с поддержкой:
            <ul>
            <li>через Личный кабинет и приложение «МегаФон»;</li>
            <li>позвонив на 0500 с номера МегаФона или на 8 800 550 05 00 с номеров других операторов;</li>
            <li>через онлайн-чат;</li>
            <li>через форму обратной связи</li>
            <li>по SMS — отправьте сообщение с вопросом на номер 0500.</li>
            </ul>
            </p>`,
            reviewSent: null
         },
         {
            id: 'web_diagnostic',
            question: 'Диагностика перед обращением в техническую поддержку',
            answer: `
            <p>Для корректной диагностики вашего устройства необходимо обеспечить подключение сетевого кабеля напрямую к вашему устройству. </p>
            <ol>
            <li>Для доступа в командную строку откройте меню Пуск (или используйте комбинацию клавиш «Win+R»).</li>
            <li>Введите «cmd» и нажмите Enter.
            <img src=${ require( '@/assets/img/faq/cmd.png' ) } alt="cmd">
            </li>
            <li>Выполните команду «ping «ip или адрес проблемного ресурса» –l 1000 –n 20» и нажмите Enter. <br>
            Например, хотите проверить пинг до сайта МегаФон, введите следующую команду: ping moscow.megafon.ru –l 1000 –n 20. 
            <img src=${ require( '@/assets/img/faq/cmd_log.png' ) } alt="cmd_log">
            </li>
            <li>tracert "ip ресурса" (например, tracert  moscow.megafon.ru)
            <img src=${ require( '@/assets/img/faq/tracert.png' ) } alt="tracert">
            *Процесс трассировки, запускаемый командой «tracert "ip ресурса"», не является быстрым. Дождитесь его окончания для отправки корректных данных специалистам технической поддержки. <br><br> 
            </li>
            <li>pathping "ip ресурса (например, pathping moscow.megafon.ru)
            <img src=${ require( '@/assets/img/faq/pathping.png' ) } alt="pathping">
            </li>
            </ol>`,
            reviewSent: null
         },
         {
            id: 'web_PPPoE',
            question: 'Как настроить Интернет-соединение?',
            answer: `
            <p>Для создания РРРоЕ-соединения на ПК изучите рекомендации:</p>
            <a target="_blank" href="/#cweb-qweb_PPPoE7">Windows 7</a> <br>
            <a target="_blank" href="/#cweb-qweb_PPPoE8">Windows 8</a> <br>
            <a target="_blank" href="/#cweb-qweb_PPPoE10">Windows 10</a> <br>
            <a target="_blank" href="/#cweb-qweb_PPPoEv">Windows Vista</a> <br>
            <a target="_blank" href="/#cweb-qweb_PPPoExp">Windows XP</a>`,
            reviewSent: null
         },
         {
            id: 'web_PPPoE7',
            question: 'PPPoE-соединение на windows 7',
            answer: `
            <ol>
            <li>Нажмите «Пуск» и откройте «Панель управления». 
            <img src=${ require( '@/assets/img/faq/win.png' ) } alt="win">
            <img src=${ require( '@/assets/img/faq/win_pusk.png' ) } alt="win_pusk">
            </li>
            <li>Заходите в «Центр управления сетями и общим доступом»
            <img src=${ require( '@/assets/img/faq/centr_upr.png' ) } alt="centr_upr">
            </li>
            <li>Выберите «Настройка нового подключения или сети».
            <img src=${ require( '@/assets/img/faq/podkl_k_seti.png' ) } alt="podkl_k_seti">
            </li>
            <li>Выбираем «Подключение к интернету», жмем «Далее».
            <img src=${ require( '@/assets/img/faq/podkl_k_inet.png' ) } alt="podkl_k_inet">
            </li>
            <li>Выбираем тип подключения «Высокоскоростное (с PPPoE)». Если подключение было настроено ранее, то появится окно. Отметьте в нем пункт «Все равно создать новое подключение», «Высокоскоростное (с PPPoE)».
            <img src=${ require( '@/assets/img/faq/PPPoE.png' ) } alt="PPPoE">
            </li>
            <li>В поле «Имя пользователя и пароль» необходимо указать соответствующие логин и пароль. Поставьте галочки «Отображать вводимые знаки», «Запомнить этот пароль».
            <img src=${ require( '@/assets/img/faq/save_pass.png' ) } alt="save_pass">
            <p>*Если на компьютере имеются другие пользователи, отметьте пункт «Разрешить использовать это подключение другим пользователям».</p> <br>
            </li>
            <li>Нажмите кнопку «Подключить». 
            <img src=${ require( '@/assets/img/faq/click_connect.png' ) } alt="click_connect">
            </li>
            <li>В дальнейшем для подключения используйте значок в правом нижнем углу экрана Вашего ПК. Для этого кликнете мышкой на иконку доступа к Интернету и выберите созданное подключение.
            <img src=${ require( '@/assets/img/faq/speed_connect.png' ) } alt="speed_connect">
            </li>
            </ol>`,
            reviewSent: null
         },
         {
            id: 'web_PPPoE8',
            question: 'PPPoE-соединение на Windows 8',
            answer: `
            <ol>
            <li>Откройте меню управления и выберите «Параметры», «Панель управления». 
            <img src=${ require( '@/assets/img/faq/win8_param.png' ) } alt="win8_param">
            <img src=${ require( '@/assets/img/faq/win8_param2.png' ) } alt="win8_param2">
            </li>
            <li>Найдите раздел «Сеть и Интернет», откройте «Подключение к Интернету».  
            <img src=${ require( '@/assets/img/faq/win8_web.png' ) } alt="win8_web">
            </li>
            <li>Выберите «Высокоскоростное (с PPPoE)».
            <img src=${ require( '@/assets/img/faq/win8_PPPoE.png' ) } alt="win8_PPPoE">
            </li>
            <li>Система запросит Ваши учетные данные, которые Вы можете найти в договоре: имя пользователя (логин) и пароль. Вы можете произвольно выставить «Имя пользователя», например, «Высокоскоростное подключение». Введите все данные и нажмите «Подключить». Через несколько секунд Вы сможете выйти в Интернет.
            <img src=${ require( '@/assets/img/faq/win8_connect.png' ) } alt="win8_connect">
            <img src=${ require( '@/assets/img/faq/win8_connect2.png' ) } alt="win8_connect2">
            </li>
            </ol>`,
            reviewSent: null
         },
         {
            id: 'web_PPPoE10',
            question: 'PPPoE-соединение на Windows 10',
            answer: `
            <ol>
            <li>Нажмите кнопку "Пуск", перейдите в раздел "Параметры".
            <img src=${ require( '@/assets/img/faq/win10_pusk.png' ) } alt="win10_pusk">
            </li>
            <li>Выберите раздел "Сеть и Интернет".
            <img src=${ require( '@/assets/img/faq/win10_params.png' ) } alt="win10_params">
            </li>
            <li>В левом столбце откройте «Набор номера».
            <img src=${ require( '@/assets/img/faq/win10_nabor.png' ) } alt="win10_nabor">
            </li>
            <li>Выберите раздел «Настройка нового подключения» 
            <img src=${ require( '@/assets/img/faq/win10_new_connect.png' ) } alt="win10_new_connect">
            </li>
            <li>Выберите пункт "Подключение к Интернету", нажмите «Далее».
            <img src=${ require( '@/assets/img/faq/win10_connect_web.png' ) } alt="win10_connect_web">
            </li>
            <li>Выберите «Высокоскоростное (с PPPoE)»
            <img src=${ require( '@/assets/img/faq/win10_PPPoE.png' ) } alt="win10_PPPoE">
            </li>
            <li>Система запросит Ваши учётные данные. Введите логин и пароль, указанные в договоре. «Имя подключения» можете выставить произвольное. После того как всё введено, нажмите кнопку «Подключить».
            <img src=${ require( '@/assets/img/faq/win10_connect_name.png' ) } alt="win10_connect_name">
            </li>
            <li>Ожидайте, пока пройдет проверка
            <img src=${ require( '@/assets/img/faq/win10_wait_connect.png' ) } alt="win10_wait_connect">
            </li>
            <li>Настройка завершена.</li>
            </ol>`,
            reviewSent: null
         },
         {
            id: 'web_PPPoEv',
            question: 'PPPoE-соединение на Windows Vista',
            answer: `
            <ol>
            <li>Нажмите «Пуск», выберите пункт меню «Подключение». 
            <img src=${ require( '@/assets/img/faq/vista_pusk.png' ) } alt="vista_pusk">
            </li>
            <li>В открывшемся окне выберите пункт "Установка подключения или сети".
            <img src=${ require( '@/assets/img/faq/vista_connect.png' ) } alt="vista_connect">
            </li>
            <li>В окне "Выберите вариант подключения" выберите "Подключение к Интернету", нажмите Далее.
            <img src=${ require( '@/assets/img/faq/vista_connect_web.png' ) } alt="vista_connect_web">
            </li>
            <li>Выберите «Высокоскоростное (с РРРоЕ)».
            <img src=${ require( '@/assets/img/faq/vista_PPPoE.png' ) } alt="vista_PPPoE">
            </li>
            <li>В открывшемся окне введите имя подключения, Ваши учетные данные (логин и пароль, указанные в памятке пользователю).  Можете использовать функцию «Запомнить пароль».  Нажмите «Подключить».  
            <img src=${ require( '@/assets/img/faq/vista_name_pass.png' ) } alt="vista_name_pass">
            <img src=${ require( '@/assets/img/faq/vista_wait.png' ) } alt="vista_wait">
            </li>
            </ol>`,
            reviewSent: null
         },
         {
            id: 'web_PPPoExp',
            question: 'PPPoE-соединение на Windows XP',
            answer: `
            <ol>
            <li>Нажмите «Пуск», подменю «Настройки» и откройте «Панель управления», «Сетевые подключения». 
            <img src=${ require( '@/assets/img/faq/winXP_pusk.png' ) } alt="winXP_pusk">
            <img src=${ require( '@/assets/img/faq/winXP_panel.png' ) } alt="winXP_panel">
            </li>
            <li>Выберите пункт «Создание нового подключения».</li>
            <li>Откроется мастер установки нового подключения. Прочтите текст и нажмите «Далее». 
            <img src=${ require( '@/assets/img/faq/winXP_master_connect.png' ) } alt="winXP_master_connect">
            </li>
            <li>Выберите «Подключить к Интернету». Нажмите «Далее». 
            <img src=${ require( '@/assets/img/faq/winXP_connect.png' ) } alt="winXP_connect">
            </li>
            <li>Отметьте «Установить подключение вручную». Нажмите «Далее». 
            <img src=${ require( '@/assets/img/faq/winXP_connect_hand.png' ) } alt="winXP_connect_hand">
            </li>
            <li>Выберите «Использовать широкополосное соединение, запрашивающее имя пользователя и пароль». Нажмите «Далее».
            <img src=${ require( '@/assets/img/faq/winXP_DSL.png' ) } alt="winXP_DSL">
            </li>
            <li>Введите произвольное «Название подключение», нажмите «Далее».</li>
            <li>Введите «имя пользователя» и «пароль». Их можно узнать в договоре, если они не были изменены в личном кабинете. 
            <img src=${ require( '@/assets/img/faq/winXP_vpn.png' ) } alt="winXP_vpn">
            </li>
            <li>Чтобы подключиться к Интернету, выберите команду «Подключение».</li>
            </ol>`,
            reviewSent: null
         },
      ]
   },
   {
      title: 'Переезд вместе с нами',
      questions: [
         {
            question: 'Как переехать без потери интернета?',
            answer: 'Если Вы решили переехать, возьмите нас с собой и продолжайте пользоваться услугами по новому адресу. Проверить, есть ли техническая возможность подключения по новому адресу можно <a href="https://home.megafon.ru/">здесь</a>. Для заказа услуги Переезд звоните <a href="tel:88005500500">8 800 55-00-500</a>',
            reviewSent: null
         },
         {
            question: 'Все ли подключенные услуги будут перенесены на новый адрес?',
            answer: 'Это зависит от технической возможности, но надеемся, что получится подключить всё, что нужно.',
            reviewSent: null
         },
         {
            question: 'Сколько стоит услуга?',
            answer: 'Услуга бесплатна. Выезд монтажника для подключения по новому адресу тоже бесплатно. Как только мы вас подключим по новому адресу, услуги по старому адресу отключатся.',
            reviewSent: null
         }
      ]
   },
   {
      title: 'Статический IP адрес',
      questions: [
         {
            question: 'Что такое статический IP адрес?',
            answer: 'Это постоянный IP-адрес, закрепленный на время пользования услуги. С ним у вас будет удаленный доступ к закрытым ресурсам сети, вы сможете организовывать собственные игровые и web серверы и играть в многопользовательские игры. Стоимость услуги 160 руб. в месяц.',
            reviewSent: null
         }
      ]
   },
   {
      id: 'eq',
      title: 'Оборудование ',
      questions: [
         {
            id: 'eq_choice',
            question: 'Как выбрать оборудование?',
            answer: 'Важно помнить, что скорость домашнего интернета зависит от пропускной способности роутера.<br>' +
               'Например, для тарифов со скоростью выше 100 мбит/с не подойдет роутер с пропускной способностью до 100 Мбит/с. Также не рекомендуем пользоваться устаревшими моделями.<br>' +
               '<br>' +
               'Для домашнего интернета вы можете использовать свой роутер, но для стабильности сигнала мы рекомендуем пользоваться роутером, который предлагается в выбранном тарифе',
            reviewSent: null
         }
      ]
   },
   {
      title: 'Антивирус',
      questions: [
         {
            question: 'Какие антивирусы доступны мне?',
            answer: 'Для защиты ваших данных и устройств от киберугроз вы можете подключить официальные антивирусные продукты компаний Kaspersky и ESET NOD32.',
            reviewSent: null
         },
         {
            question: 'Как выбрать какой антивирус мне нужен?',
            answer: 'Рекомендуем исходить от частоты использования устройств. Услуги анитвирусов можно подключить от одного до трех устройств. Также вы можете обезопасить устройство ваших детей с помощью антивируса Родительский контроль Kaspersky Safe kids.',
            reviewSent: null
         },
         {
            question: 'Как подключить антивирус?',
            answer: 'Вам доступны услуги антивируса, которые вы можете подключить в личном кабинете.',
            reviewSent: null
         },
         {
            question: 'Сколько стоят услуги антивируса?',
            answer: 'Стоимость услуг антивируса зависит от количества подключаемых устройств',
            reviewSent: null
         }
      ]
   },
   {
      title: 'В МегаФон со своим номером',
      questions: [
         {
            question: 'Условия переноса номера: ',
            answer: '• вы — владелец переносимого номера либо являетесь доверенным лицом владельца переносимого номера и имеете при себе нотариально заверенную доверенность;<br>' +
               '• паспортные данные в договоре с мобильным оператором соответствуют текущим паспортным данным;<br>' +
               '• на номере на момент переноса, нет задолженности;<br>' +
               '• номер не заблокирован;<br>' +
               '• номер — федеральный, а не городской;<br>' +
               '• ваш номер ранее не переносился или с момента последнего переноса прошло более 60 дней.',
            reviewSent: null
         },
      ]
   },
   {
      title: 'Скидки близким',
      questions: [
         {
            question: 'Как подключить всю семью к МегаФону со скидкой 40%?',
            answer: '<p>Абоненты тарифов «Объединяй» могут подключить своих близких к тарифу «Без переплат.Всё» со скидкой 40% навсегда. Оплата каждой дополнительной sim  карты производится отдельно.  Для получения дополнительной информации и подключения:</p>' +
               '<ol>' +
               '<li>Позвоните по номеру <a href="tel:88005500001">8 800 55-00-001</a>.</li>' +
               '<li>Закажите SIM-карту (максимум — 10 штук) с тарифом «Без переплат. Всё».</li>' +
               '<li>Дождитесь сотрудника с SIM-картой и заявлением о переносе номера, если необходимо его сохранить.</li>' +
               '</ol>',
            reviewSent: null
         },
      ]
   },
]
